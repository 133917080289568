.main {
  height: 100vh !important;
  width: 100%;
  margin: 0;
  padding: auto;
  flex-direction: row;
  display: flex;
}


.container {
  height: 36rem;
  width: 30rem !important;
  background-color: #ffffffbf;
  z-index: 1000;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: Grandstander;
}

.by {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 2rem;
}

.by span {
  font-size: 3rem;
  color: #0067c6;
  font-weight: 900;
  font-family: 'Grandstander';
  margin-left: 1rem;
}

.pics {
  flex: 1;
  width: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pics img {
  width: 20rem;
}

.pics #tsparticles canvas {
    height: 100%;
    position: fixed;
    z-index: 0;
    top: 0px;
    width: 50% !important;
    right: 0 !important;
    left: auto !important;
    pointer-events: initial;
}

.login div button {
  background-color: rgb(0 179 228) !important;
  display: inline-flex;
  align-items: center;
  color: rgb(255 255 255) !important;
  margin-top: 1rem;
}


.login {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login img {
  width: 17rem;
}
