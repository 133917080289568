.table-row {
    height: 10rem;
    padding: 1rem;
    flex-direction: column;
    display: flex;
    min-width: 40rem;
    cursor: pointer;
}

.table-row-headline {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.table-row-name {

}

.table-row-name p {
    font-size: xx-large;
    color: #0256a9;
}

.table-row-tray {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.table-row-tray p {
    font-weight: bolder;
    color: #1976d2;
}

.table-row-tray p svg {
    fill: #1976d2;
}

.table-row-tray > * {
    margin-left: 3rem !important;
}

.table-row-tray > *:first-child {
    margin-left: 0rem !important;
}    

.table-row-description {
    margin-top: 1rem;
}