.mainpage {
    margin-top: 100px;
    width: 100%;
    display: flex;
    height: calc(100vh - 100px) !important;
    padding: 3rem;
    color: #1976d2;
    display: flex;
    flex-direction: column;
}

.dash {
    display: flex;
    flex-direction: row;
    width: 100% !important;
    min-height: 20rem;
}

.names {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 80%;
    flex: 2;
}

.names * {
    margin: 10px;
}

.info {
    display: flex;
    flex-direction: column;
    flex: 8;
    height: 100%;
    margin-left: 5rem;
    height: 80%;
}

.modelBody {
    overflow: scroll;
}

.names ul {
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
}

.detail {
    cursor: pointer;
    color: blue;
}

.modelHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #00aad7;
}

.modelHeader p {
    justify-content: center;
    align-items: center;
    height: 100%;
    display: flex;
    color: white;
    margin: 10px;
    align-items: center;
}

.modelHeader button {
    border: 0;
}

.meta {
    height: 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    color: black;
    border-radius: 1rem 1rem 0rem 0rem;
    padding: 10px;
}

.meta chip {
    color: white;
}

.names li {
    list-style: none;
    width: 100%;
    display: flex;
    align-items: center;
    height: 40px;
    /* border: 1px solid; */
    justify-content: center;
    /* border: 1px solid #cbcbcb; */
    cursor: pointer;
    box-shadow: 4px 4px 8px 0px rgb(50 50 50 / 30%);
}
::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}

.info img {
    width: 50rem;
    align-self: center;
}

.bt {
    width: 10rem;
}

.table th {
    color: black !important;
    background-color: white !important;
    z-index: 0;
}

.table>*:first-child {
    box-shadow: none !important;
}

.loading-main {
    height: 100vh !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}