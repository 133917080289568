.column-view {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.table-details {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 22rem;
    border-right: 2px solid #00b3e4;
    justify-content: space-around;
}

.table-details p {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    /* width: 20%; */
    font-weight: 900;
    letter-spacing: 0.00938em;
    color: #096078ab;
}

.columns {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    overflow: scroll;
}

.column-row {
    height: 10rem;
    padding: 1rem;
    flex-direction: column;
    display: flex;
    min-width: 40rem;
    cursor: pointer;
    border-bottom: 1px solid #b1acac;
}

.table-row-headline {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.table-row-name {

}

.table-row-name p {
    font-size: xx-large;
    color: #0256a9;
}

.table-row-tray {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.table-row-tray p {
    font-weight: bolder;
    color: #1976d2;
}

.table-row-tray p svg {
    fill: #1976d2;
}

.table-row-tray > * {
    margin-left: 3rem !important;
}

.table-row-tray > *:first-child {
    margin-left: 0rem !important;
}    

.table-row-description {
    margin-top: 1rem;
}

.editButton {
    cursor: pointer;
}

.model-content {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.model-content > * {
    margin-top: 2rem;
}

.model-content > *:first-child {
    margin-top: 0;
}

.model-content .tableTags ul {
    max-height: 15rem;
    overflow: scroll;
    list-style: none;
}

.description-input {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.description-input>*:last-child {
    align-self: flex-end ;
}