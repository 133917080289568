.events {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100%;
    flex-direction: column;
    justify-content: space-around;
}

.graph {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.can {
    width: 40%;
    max-height: 50%;
}