.sidebar {
    flex: 1;
    height: calc(100vh -60px) !important;
    background-color: rgb(251, 251, 255);
    position: sticky;
    width: 15rem;
    max-width: 10rem;
  }
  
  .sidebarWrapper {
    margin-top: 15px;
    padding: 20px;
    color: #555;
  }
  
  .sidebarMenu {
    margin-bottom: 10px;
    color: #698097;
  }
  
  .sidebarTitle {
    font-size: 13px;
    color: rgb(187, 186, 186);
  }
  
  .sidebarList {
    list-style: none;
    padding: 5px;
    height: 15rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  
  .sidebarListItem {
    padding: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 10px;
  }
  
  .sidebarListItem.active,
  .sidebarListItem:hover {
    background-color: rgb(240, 240, 255);
  }
  
  .sidebarIcon{
      margin-right: 5px;
      font-size: 20px !important;
  }
  .sidebar-bottom {
    justify-content: center;
    border: none;
    display: flex;
    width: 80%;
    border-top: 1px solid #4b4b4b;
    padding: 10px 0;
    margin-bottom: 10px;
    align-items: center;
  }
  .color-box {
    width: 20px;
    height: 20px;
    border-radius: 5px;
    border: 1px solid #9ca3af;
    margin-right: 15px;
    cursor: pointer;
  }
  
  .dark {
    background-color: #111827;
  }
  
  .night {
    background-color: #312e81;
  }
  
  .light {
    background-color: #f3f4f6;
  }