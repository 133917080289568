.appcontainer {
    width: calc(100% - 15rem);
    display: flex;
    height: calc(100vh - 60px) !important
}

.landing {
    display: flex;
    flex-direction: column;
    width: 100% !important;
}

.details {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 10rem;
}

.details label {
    font-family: 'Grandstander';
    color: #01b3e4;
}

.details div {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    margin-left: 3rem;
    border-left: 0.5rem solid #0bb6e5;
    padding-left: 1rem;
}

.details div small {
    font-weight: 900;
    color: #5f87ab;
}

.name {
    font-size: 4rem ;
}

.landing .details img {
    border-radius: 50%;
    border: 0.5rem solid #0bb6e5;
    width: 10rem;
}

.links {
    width: 100% !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-top: 10rem;
}

.links .link {
    height: 20rem;
    width: 30%;
    background-color: #0bb6e5;
    box-shadow: 10px 10px 59px 15px rgba(36, 37, 37, 0.8);
    -webkit-box-shadow: 10px 10px 59px 15px rgba(36, 37, 37, 0.8);
    -moz-box-shadow: 10px 10px 59px 15px rgba(36, 37, 37, 0.8);
    border-radius: 1rem;
    font-family: 'Grandstander';
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
}

.links .link:hover {
    transform: scale(1.3) skew(1deg);
    -webkit-transform: scale(1.3) skew(1deg);
    -moz-transform: scale(1.3) skew(1deg);
    transition-duration: 1s;
}
.content {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    height: 80%;
}

.content img {
    width: 10rem;
}

.content h1 {
    font-family: 'Grandstander';
}

.err {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.err h1 {
    font-family: 'Grandstander';
    color: #01b3e4;
}

.err .bt {
    width: 10rem;
}

.link-bg {
    background: linear-gradient(270deg, #a950ca, #1485c5, #47b0ec);
    background-size: 600% 600%;

    -webkit-animation: AnimationName 7s ease infinite;
    -moz-animation: AnimationName 7s ease infinite;
    animation: AnimationName 7s ease infinite;
}

@-webkit-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-moz-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
