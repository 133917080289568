::-webkit-scrollbar-thumb {
    background: #1976d2;
}

.gc-logo {
    width: 6rem;
}

.org {
    display: flex;
    align-items: center;
}

.org img {
    margin-right: 1rem;
}

.main-body {
    padding: 3rem;
    margin-top: 2rem;
}

.link {
    text-decoration: none;
}

.err {
    color: #0067c6;
}
.main-body > * {
    margin-top: 2rem !important;
}

.dashboards {
    color: #6c757d;
}

.items > * {
    margin-top: 2rem;
}

.items .body {
    display: flex;
    justify-content: flex-start;
    margin-top: 5rem;
}

.items .footer {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: row;
}

.itmes .footer>*:last-child {
    width:30rem !important;
}

.items .body> * {
    margin-right: 5rem;
}

.heading-icon {
    font-size: 3.3rem !important;
}

.profile-stack {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.profile-stack .MuiOutlinedInput-notchedOutline {
    padding: 0;
    color: white;
    border: none !important;
}

.profile-stack .MuiOutlinedInput-root {
    display: flex;
    flex-direction: column;
}

.profile-stack .MuiSelect-select {
    padding: 0.5rem !important;
}
  
.profile-stack svg {
    fill: white !important;
}

.main-footer {
    height: 15rem !important;
    /* width: 100vh; */
    margin-top: 5rem;
    background: #1976d2;
    color: white;
    padding: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}


.details img {
    border-radius: 50%;
}