.error-main {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.error-main button {
    border: none;
    background-color: #00b3e4;
    height: 3rem;
    width: 25rem;
    cursor: pointer;
    color: white;
}

.error-main button span {
    color: red;
}

.error_logo {
    margin-bottom: 1rem;
    width: 25rem;
}