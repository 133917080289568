.kpi {
    display: flex;
    min-height: calc(100vh - 60px) !important;
    background-color: white;
}

.data {
    display: flex;
    flex-direction: column;
    width: 100% !important;
    padding: 1rem;
    background-color: white;
    margin-top: 5rem;
}

.schemas {
    width: 100% !important;
}

.schema {
    cursor: pointer;
    width: 15rem;
    height: 15rem;
    background-color: #00b3e4;
    color: white;
    border: 2px solid;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}


.custom-heading {
    color: #00b3e4;
    padding: 1rem;
    width: 15rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.catalog {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: white;
    height: 46rem;
}

.filter {
    width: 15rem;
    height: auto;
    padding: 2rem;
    background-color: white;
    max-height: 46rem;
}

.filter ul {
    list-style: none;
}

.filter-right {
    border-left: 2px solid #00b3e4;
}

.filter-left {
    border-right: 2px solid #00b3e4;
}

.tag-list {
    overflow: scroll;
}

.results {
    background: white;
    width: -webkit-fill-available;
    height: 46rem;
    overflow: scroll;
    padding: 1rem;
}

.results ul {
    list-style: none;
    padding: 0;
}

.results ul > * {
    border-bottom: 1px solid #bfbfbf;
}

.results ul > *:last-of-type {
    border-bottom: 0
}

.gif  {
    background: white;
    width: -webkit-fill-available;
    justify-content: center;
    align-items: center;
    display: flex;
}

.gif img {
    width: 5rem;
}

.options {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

::-webkit-scrollbar {
    width: 1rem;  /* Remove scrollbar space */
    background: white;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: #00b3e4;
}