.home{
    overflow-x:hidden;
    max-height: 100%;
}
.homeWidgets{
    display: flex;
    margin: 20px;
}
.body {
    height: 100% !important;
    width: 100% !important;
  }