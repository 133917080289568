.chart {
    margin: 20px;
    padding: 20px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  }
  
  .chartTitle {
    margin-bottom: 20px;
  }
  .chartDelete{
    color: red;
    cursor: pointer;
    float: right;
}
.chartAdd{
  color: green;
  cursor: pointer;
  float: right;
}