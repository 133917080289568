.appcontainer{
    display: flex;
}
.link{
    text-decoration: none;
    color: inherit;
}
.body {
    height: 100% !important;
    width: 100% !important;
  }

